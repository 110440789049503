var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"contenedor-main"},[_c('v-container',{staticClass:"contenedor-main"},[_c('v-row',[(_vm.mostrarNovedades)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-container',{staticClass:"infoData",style:({ padding: '2px' })},[_c('v-badge',{staticClass:"top",attrs:{"value":_vm.infoD,"content":"Cantidad de estudiantes por alerta y curso","left":"","transition":"slide-x-transition"}},[_c('v-hover',{model:{value:(_vm.infoD),callback:function ($$v) {_vm.infoD=$$v},expression:"infoD"}},[_c('v-icon',{staticClass:"infoIcon"},[_vm._v(" mdi-information-outline ")])],1)],1)],1),_c('h4',{staticClass:"titleN"},[_vm._v("Novedades por curso")]),_c('v-toolbar',{attrs:{"color":"transparent","flat":""}},[_c('div',{staticStyle:{"overflow-x":"auto","white-space":"nowrap"}},_vm._l((_vm.periodosAlertas),function(itemP,index){return _c('v-container',{key:index,staticClass:"menuBar",attrs:{"id":itemP},on:{"click":function($event){return _vm.showCodes(itemP)}}},[_c('v-row',[_c('v-col',{style:({ padding: '8px' })},[_c('span',{staticClass:"textMenu"},[_vm._v(" "+_vm._s(itemP))]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.valores[index] != '0'),expression:"valores[index] != '0'"}],attrs:{"dark":"","medium":"","right":"","color":"orange"}},[_vm._v(" mdi-alert-octagon ")])],1)],1)],1)}),1)]),(_vm.tableDT == true)?_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headersAlertas,"items":_vm.itemsAlertas,"search":_vm.searchN,"no-results-text":"Sin resultados","sort-by":"Nombre","header-props":{ sortByText: 'Organizar por' },"footer-props":{
                'items-per-page-text': 'items por página',
                'items-per-page-all-text': 'Todos',
                'items-per-page-options': [-1, 5, 10, 15],
              },"dense":"","items-per-page":_vm.itemsAlertas.length},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{style:({ 'margin-top': '-40px' })},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchN),callback:function ($$v) {_vm.searchN=$$v},expression:"searchN"}})],1)]},proxy:true}],null,false,4009738181)})],1):_vm._e(),(_vm.tableDT == false)?_c('v-container',[_c('line-chart',{attrs:{"chart-data":_vm.novedadesG}})],1):_vm._e()],1)],1):_vm._e(),(!_vm.mostrarNovedades)?_c('v-container',[_c('v-progress-circular',{attrs:{"width":3,"size":20,"color":"primary","indeterminate":""}})],1):_vm._e(),(_vm.adminLevel != 1 && _vm.mostrarCartera)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-container',{staticClass:"infoData"},[_c('v-badge',{staticClass:"top",attrs:{"value":_vm.infoC,"content":"Cantidad de dinero recolectado y faltante por periodo","left":"","transition":"slide-x-transition"}},[_c('v-hover',{model:{value:(_vm.infoC),callback:function ($$v) {_vm.infoC=$$v},expression:"infoC"}},[_c('v-icon',{staticClass:"infoIcon"},[_vm._v(" mdi-information-outline ")])],1)],1)],1),_c('h4',{staticClass:"titleN"},[_vm._v("Información de cartera")]),_c('v-data-table',{attrs:{"headers":_vm.headersC,"items":_vm.cartera,"search":_vm.searchC,"no-results-text":"Sin resultados","sort-by":"Nombre","dense":"","header-props":{ sortByText: 'Organizar por' },"items-per-page":_vm.cartera.length,"footer-props":{
              'items-per-page-text': 'items por página',
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [-1, 5, 10, 15],
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{style:({ 'margin-top': '-40px' })},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchC),callback:function ($$v) {_vm.searchC=$$v},expression:"searchC"}})],1)]},proxy:true},{key:"item.valor.pagosLineas",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pagos por linea")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.pagosLineas),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.pagosMedios",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pagos por medio de venta")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.pagosMedios),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.pendienteLineas",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pendiente por linea")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.pendienteLineas),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.ventasLineas",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Planeado por linea")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.ventasLineas),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.pendiente",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.pendiente))+" ")])]}},{key:"item.valor.pendienteFecha",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.pendienteFecha))+" ")])]}},{key:"item.valor.pagosSinDPS",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.valor.pagosSinDPS * 100 + "%")+" ")])]}},{key:"item.valor.perdidasDPS",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.perdidasDPS))+" ")])]}},{key:"item.valor.planeado",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.planeado))+" ")])]}},{key:"item.valor.enPagos",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.enPagos))+" ")])]}},{key:"item.valor.recolectado",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.recolectado))+" ")])]}}],null,true)}),_c('h4',{staticClass:"notaText"},[_vm._v(" Deuda actual total: "+_vm._s(_vm._f("currency")(_vm.deudaT))+" ")])],1)],1):_vm._e(),(!_vm.mostrarCartera)?_c('v-container',[_c('v-progress-circular',{attrs:{"width":3,"size":20,"color":"primary","indeterminate":""}})],1):_vm._e(),(_vm.adminLevel != 1 && _vm.mostrarCarteraT)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-container',{staticClass:"infoData"},[_c('v-badge',{staticClass:"top",attrs:{"value":_vm.infoCT,"content":"Cantidad de dinero recolectado y faltante TRABAJOS","left":"","transition":"slide-x-transition"}},[_c('v-hover',{model:{value:(_vm.infoCT),callback:function ($$v) {_vm.infoCT=$$v},expression:"infoCT"}},[_c('v-icon',{staticClass:"infoIcon"},[_vm._v(" mdi-information-outline ")])],1)],1)],1),_c('h4',{staticClass:"titleN"},[_vm._v("Información de cartera por trabajos")]),_c('v-data-table',{attrs:{"headers":_vm.headersCT,"items":_vm.carteraT,"search":_vm.searchCT,"no-results-text":"Sin resultados","sort-by":"Nombre","dense":"","header-props":{ sortByText: 'Organizar por' },"items-per-page":_vm.carteraT.length,"footer-props":{
              'items-per-page-text': 'items por página',
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [-1, 5, 10, 15],
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{style:({ 'margin-top': '-40px' })},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchCT),callback:function ($$v) {_vm.searchCT=$$v},expression:"searchCT"}})],1)]},proxy:true},{key:"item.valor.pagosLineas",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pagos por linea")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.pagosLineas),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.pagosMedios",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pagos por medio de venta")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.pagosMedios),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.pendiente",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.pendiente))+" ")])]}},{key:"item.valor.pendienteLineas",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pendiente por linea")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.pendienteLineas),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.ventasLineas",fn:function(ref){
            var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Planeado por linea")])],1)]},proxy:true}],null,true)},_vm._l((item.valor.ventasLineas),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + ": "))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(data)))])],1)],1)}),1)]}},{key:"item.valor.pendienteFecha",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.pendienteFecha))+" ")])]}},{key:"item.valor.pagosSinDPS",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.valor.pagosSinDPS * 100 + "%")+" ")])]}},{key:"item.valor.perdidasDPS",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.perdidasDPS))+" ")])]}},{key:"item.valor.planeado",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.planeado))+" ")])]}},{key:"item.valor.enPagos",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.enPagos))+" ")])]}},{key:"item.valor.recolectado",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor.recolectado))+" ")])]}}],null,true)}),_c('h4',{staticClass:"notaText"},[_vm._v(" Deuda actual total trabajos: "+_vm._s(_vm._f("currency")(_vm.deudaTCT))+" ")])],1)],1):_vm._e(),(!_vm.mostrarCarteraT)?_c('v-container',[_c('v-progress-circular',{attrs:{"width":3,"size":20,"color":"primary","indeterminate":""}})],1):_vm._e(),(_vm.mostrarEstudiantes)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-container',{staticClass:"infoData"},[_c('v-badge',{staticClass:"top",attrs:{"value":_vm.infoE,"content":"Cantidad de estudiantes por periodos y materias","right":"","transition":"slide-x-transition"}},[_c('v-hover',{model:{value:(_vm.infoE),callback:function ($$v) {_vm.infoE=$$v},expression:"infoE"}},[_c('v-icon',{staticClass:"infoIcon"},[_vm._v(" mdi-information-outline ")])],1)],1)],1),_c('h4',{staticClass:"titleN"},[_vm._v("Estudiantes por periodos y materias")]),_c('v-toolbar',{style:({ padding: '0' }),attrs:{"color":"transparent","flat":"","dense":""}},[_c('div',{staticStyle:{"overflow-x":"auto","white-space":"nowrap"}},_vm._l((_vm.tiposE),function(itemE,index){return _c('v-container',{key:index,staticClass:"menuBar",attrs:{"id":itemE},on:{"click":function($event){return _vm.mostrarE(itemE, _vm.tiposE)}}},[_c('v-row',[_c('v-col',{style:({ padding: '8px' })},[_c('span',{staticClass:"textMenu"},[_vm._v(" "+_vm._s(itemE))])])],1)],1)}),1)]),(_vm.tableET == true)?_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headersE,"items":_vm.infoEstudiantes,"no-results-text":"Sin resultados","sort-by":"Nombre","header-props":{ sortByText: 'Organizar por' },"footer-props":{
                'items-per-page-text': 'items por página',
                'items-per-page-all-text': 'Todos',
              },"dense":""}})],1):_vm._e(),(_vm.tableET == false)?_c('v-container',[_c('line-chart',{attrs:{"chart-data":_vm.infoEstudiantesG}})],1):_vm._e()],1)],1):_vm._e(),(!_vm.mostrarEstudiantes)?_c('v-container',[_c('v-progress-circular',{attrs:{"width":3,"size":20,"color":"primary","indeterminate":""}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }