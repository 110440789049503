<template>
  <v-container class="contenedor-main">
    <v-container class="contenedor-main">
      <v-row>
        <v-col cols="12" md="12" v-if="mostrarNovedades">
          <v-card outlined elevation="0">
            <v-container class="infoData" v-bind:style="{ padding: '2px' }">
              <v-badge
                :value="infoD"
                class="top"
                content="Cantidad de estudiantes por alerta y curso"
                left
                transition="slide-x-transition"
              >
                <v-hover v-model="infoD">
                  <v-icon class="infoIcon"> mdi-information-outline </v-icon>
                </v-hover>
              </v-badge>
            </v-container>
            <h4 class="titleN">Novedades por curso</h4>
            <v-toolbar color="transparent" flat>
              <div style="overflow-x: auto; white-space: nowrap">
                <v-container
                  class="menuBar"
                  :id="itemP"
                  v-for="(itemP, index) in periodosAlertas"
                  :key="index"
                  @click="showCodes(itemP)"
                >
                  <v-row>
                    <v-col v-bind:style="{ padding: '8px' }">
                      <span class="textMenu"> {{ itemP }}</span>
                      <v-icon
                        v-show="valores[index] != '0'"
                        dark
                        medium
                        right
                        color="orange"
                      >
                        mdi-alert-octagon
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-toolbar>
            <v-container v-if="tableDT == true">
              <v-data-table
                :headers="headersAlertas"
                :items="itemsAlertas"
                :search="searchN"
                no-results-text="Sin resultados"
                sort-by="Nombre"
                :header-props="{ sortByText: 'Organizar por' }"
                :footer-props="{
                  'items-per-page-text': 'items por página',
                  'items-per-page-all-text': 'Todos',
                  'items-per-page-options': [-1, 5, 10, 15],
                }"
                dense
                :items-per-page="itemsAlertas.length"
              >
                <template v-slot:top>
                  <v-card-title v-bind:style="{ 'margin-top': '-40px' }">
                    <v-text-field
                      v-model="searchN"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </template>
              </v-data-table>
            </v-container>
            <v-container v-if="tableDT == false">
              <line-chart :chart-data="novedadesG"></line-chart>
            </v-container>
          </v-card>
        </v-col>
        <v-container v-if="!mostrarNovedades">
          <v-progress-circular
            :width="3"
            :size="20"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-container>

        <v-col cols="12" md="12" v-if="adminLevel != 1 && mostrarCartera">
          <v-card outlined elevation="0">
            <v-container class="infoData">
              <v-badge
                :value="infoC"
                class="top"
                content="Cantidad de dinero recolectado y faltante por periodo"
                left
                transition="slide-x-transition"
              >
                <v-hover v-model="infoC">
                  <v-icon class="infoIcon"> mdi-information-outline </v-icon>
                </v-hover>
              </v-badge>
            </v-container>
            <h4 class="titleN">Información de cartera</h4>
            <v-data-table
              :headers="headersC"
              :items="cartera"
              :search="searchC"
              no-results-text="Sin resultados"
              sort-by="Nombre"
              dense
              :header-props="{ sortByText: 'Organizar por' }"
              :items-per-page="cartera.length"
              :footer-props="{
                'items-per-page-text': 'items por página',
                'items-per-page-all-text': 'Todos',
                'items-per-page-options': [-1, 5, 10, 15],
              }"
            >
              <template v-slot:top>
                <v-card-title v-bind:style="{ 'margin-top': '-40px' }">
                  <v-text-field
                    v-model="searchC"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </template>
              <template v-slot:[`item.valor.pagosLineas`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Pagos por linea</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.pagosLineas"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-slot:[`item.valor.pagosMedios`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        >Pagos por medio de venta</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.pagosMedios"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-slot:[`item.valor.pendienteLineas`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Pendiente por linea</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.pendienteLineas"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-slot:[`item.valor.ventasLineas`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Planeado por linea</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.ventasLineas"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>

              <template v-slot:[`item.valor.pendiente`]="{ item }">
                <span> {{ item.valor.pendiente | currency }} </span>
              </template>

              <template v-slot:[`item.valor.pendienteFecha`]="{ item }">
                <span> {{ item.valor.pendienteFecha | currency }} </span>
              </template>
              <template v-slot:[`item.valor.pagosSinDPS`]="{ item }">
                <span> {{ item.valor.pagosSinDPS * 100 + "%" }} </span>
              </template>
              <template v-slot:[`item.valor.perdidasDPS`]="{ item }">
                <span> {{ item.valor.perdidasDPS | currency }} </span>
              </template>
              <template v-slot:[`item.valor.planeado`]="{ item }">
                <span> {{ item.valor.planeado | currency }} </span>
              </template>
              <template v-slot:[`item.valor.enPagos`]="{ item }">
                <span> {{ item.valor.enPagos | currency }} </span>
              </template>
              <template v-slot:[`item.valor.recolectado`]="{ item }">
                <span> {{ item.valor.recolectado | currency }} </span>
              </template>
            </v-data-table>
            <h4 class="notaText">
              Deuda actual total: {{ deudaT | currency }}
            </h4>
          </v-card>
        </v-col>
        <v-container v-if="!mostrarCartera">
          <v-progress-circular
            :width="3"
            :size="20"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-container>
        <!-- Cartera de TRABAJOS -->
        <v-col cols="12" md="12" v-if="adminLevel != 1 && mostrarCarteraT">
          <v-card outlined elevation="0">
            <v-container class="infoData">
              <v-badge
                :value="infoCT"
                class="top"
                content="Cantidad de dinero recolectado y faltante TRABAJOS"
                left
                transition="slide-x-transition"
              >
                <v-hover v-model="infoCT">
                  <v-icon class="infoIcon"> mdi-information-outline </v-icon>
                </v-hover>
              </v-badge>
            </v-container>
            <h4 class="titleN">Información de cartera por trabajos</h4>
            <v-data-table
              :headers="headersCT"
              :items="carteraT"
              :search="searchCT"
              no-results-text="Sin resultados"
              sort-by="Nombre"
              dense
              :header-props="{ sortByText: 'Organizar por' }"
              :items-per-page="carteraT.length"
              :footer-props="{
                'items-per-page-text': 'items por página',
                'items-per-page-all-text': 'Todos',
                'items-per-page-options': [-1, 5, 10, 15],
              }"
            >
              <template v-slot:top>
                <v-card-title v-bind:style="{ 'margin-top': '-40px' }">
                  <v-text-field
                    v-model="searchCT"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </template>
              <template v-slot:[`item.valor.pagosLineas`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Pagos por linea</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.pagosLineas"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-slot:[`item.valor.pagosMedios`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        >Pagos por medio de venta</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.pagosMedios"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-slot:[`item.valor.pendiente`]="{ item }">
                <span> {{ item.valor.pendiente | currency }} </span>
              </template>
              <template v-slot:[`item.valor.pendienteLineas`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Pendiente por linea</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.pendienteLineas"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-slot:[`item.valor.ventasLineas`]="{ item }">
                <v-list-group :value="false" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Planeado por linea</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(data, index) in item.valor.ventasLineas"
                    :key="data + index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ index + ": " }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-slot:[`item.valor.pendienteFecha`]="{ item }">
                <span> {{ item.valor.pendienteFecha | currency }} </span>
              </template>
              <template v-slot:[`item.valor.pagosSinDPS`]="{ item }">
                <span> {{ item.valor.pagosSinDPS * 100 + "%" }} </span>
              </template>
              <template v-slot:[`item.valor.perdidasDPS`]="{ item }">
                <span> {{ item.valor.perdidasDPS | currency }} </span>
              </template>
              <template v-slot:[`item.valor.planeado`]="{ item }">
                <span> {{ item.valor.planeado | currency }} </span>
              </template>
              <template v-slot:[`item.valor.enPagos`]="{ item }">
                <span> {{ item.valor.enPagos | currency }} </span>
              </template>
              <template v-slot:[`item.valor.recolectado`]="{ item }">
                <span> {{ item.valor.recolectado | currency }} </span>
              </template>
            </v-data-table>
            <h4 class="notaText">
              Deuda actual total trabajos: {{ deudaTCT | currency }}
            </h4>
          </v-card>
        </v-col>
        <v-container v-if="!mostrarCarteraT">
          <v-progress-circular
            :width="3"
            :size="20"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-container>

        <v-col cols="12" md="4" v-if="mostrarEstudiantes">
          <v-card outlined elevation="0">
            <v-container class="infoData">
              <v-badge
                :value="infoE"
                class="top"
                content="Cantidad de estudiantes por periodos y materias"
                right
                transition="slide-x-transition"
              >
                <v-hover v-model="infoE">
                  <v-icon class="infoIcon"> mdi-information-outline </v-icon>
                </v-hover>
              </v-badge>
            </v-container>
            <h4 class="titleN">Estudiantes por periodos y materias</h4>
            <v-toolbar
              v-bind:style="{ padding: '0' }"
              color="transparent"
              flat
              dense
            >
              <div style="overflow-x: auto; white-space: nowrap">
                <v-container
                  class="menuBar"
                  :id="itemE"
                  v-for="(itemE, index) in tiposE"
                  :key="index"
                  @click="mostrarE(itemE, tiposE)"
                >
                  <v-row>
                    <v-col v-bind:style="{ padding: '8px' }">
                      <span class="textMenu"> {{ itemE }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-toolbar>
            <v-container v-if="tableET == true">
              <v-data-table
                :headers="headersE"
                :items="infoEstudiantes"
                no-results-text="Sin resultados"
                sort-by="Nombre"
                :header-props="{ sortByText: 'Organizar por' }"
                :footer-props="{
                  'items-per-page-text': 'items por página',
                  'items-per-page-all-text': 'Todos',
                }"
                dense
              >
              </v-data-table>
            </v-container>
            <v-container v-if="tableET == false">
              <line-chart :chart-data="infoEstudiantesG"></line-chart>
            </v-container>
          </v-card>
        </v-col>
        <v-container v-if="!mostrarEstudiantes">
          <v-progress-circular
            :width="3"
            :size="20"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-container>
      </v-row>
    </v-container>
    <!-- <v-container v-if="mostrarInfo == false">
      <v-progress-circular
        :width="3"
        :size="20"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-container> -->
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import getUser from './Tools/user.js'

export default {
  components: {},
  data() {
    return {
      mostrarNovedades: false,
      mostrarCartera: false,
      mostrarCarteraT: false,
      mostrarEstudiantes: false,
      // novedades
      periodosAlertas: [],
      searchC: "",
      searchN: "",
      searchCT: "",
      periodo: null,
      codesAlertas: [],
      code: null,
      infoAlertas: [],
      alertas: null,
      filtroM: [],
      headersAlertas: [],
      itemsAlertas: [],
      infoTNoved: [],
      //prueba
      listaMaterias: [],
      listaPeriodos: [],
      info: false,
      infoDC: false,
      novedades: [],
      novedadesG: [],
      lablesD: [],
      dataD: [],
      infoD: false,
      tableDT: null,
      tableDocentes: [],
      headersDocentes: [
        {
          // .btnAlerta {
          //   text-transform: none !important;
          //   color: black !important;
          //   font-size: 12px !important;
          //   margin-right: 12px !important;
          // }

          // .v-alert {
          //   margin-bottom: 6px !important;
          // }
          text: "Cantidad de cursos",
          align: "start",
          value: "cantidad",
        },
        { text: "Docentes", value: "docentes" },
      ],
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        { text: "Cantidad", value: "cantidad" },
      ],
      valores: [],
      chartDataDocentes: {},
      tiposE: ["periodos", "materias"],
      dataEstudiantes: [],
      infoEstudiantes: [],
      //datos indi Estudiantes
      lablesE: [],
      tableCT: null,
      dataE: [],
      infoEstudiantesG: [],
      infoE: false,
      tableET: null,
      headersE: [
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        { text: "Cantidad Total", value: "total" },
        { text: "Cantidad sin DPS", value: "DPS" },
      ],
      //datos indi Cartera
      cartera: [],
      deudaT: null,
      infoC: false,
      headersC: [
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        // { text: 'Categoría', value: 'categoria' },
        { text: "Pendiente", value: "valor.pendiente" },
        { text: "Pendiente por lineas", value: "valor.pendienteLineas" },
        { text: "Pendiente a la fecha", value: "valor.pendienteFecha" },
        { text: "Pagos sin DPS", value: "valor.pagosSinDPS" },
        { text: "Perdidas DPS", value: "valor.perdidasDPS" },
        { text: "Negociado", value: "valor.planeado" },
        { text: "Negociado en pagos", value: "valor.enPagos" },
        { text: "Planeado por lineas", value: "valor.ventasLineas" },
        { text: "Recolectado", value: "valor.recolectado" },
        { text: "Recolectado por Linea", value: "valor.pagosLineas" },
        { text: "Recolectado por Medio de venta", value: "valor.pagosMedios" },
      ],
      // datos indi cartera trabajos
      carteraT: [],
      deudaTCT: null,
      infoCT: false,
      headersCT: [
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        // { text: 'Categoría', value: 'categoria' },
        { text: "Pendiente", value: "valor.pendiente" },
        { text: "Pendiente por lineas", value: "valor.pendienteLineas" },
        { text: "Pendiente a la fecha", value: "valor.pendienteFecha" },
        { text: "Planeado", value: "valor.planeado" },
        { text: "Planeado por lineas", value: "valor.ventasLineas" },
        { text: "Recolectado", value: "valor.recolectado" },
        { text: "Recolectado por Linea", value: "valor.pagosLineas" },
        { text: "Recolectado por medio de venta", value: "valor.pagosMedios" },
      ],

      adminLevel: null,
      // recolectado
      headersR: [
        { text: "Recolectado por linea", value: "valor.linea" },
        { text: "Recolectado por venta", value: "valor.medioVenta" },
      ],
      searchR: "",
      recolectado: [],
      infoR: false,
      // cantidad de estudiantes por docente
      headersExD: [
        { text: "Docente", value: "valor.tutor" },
        { text: "Con DPS", value: "valor.conDps" },
        { text: "Sin Dps", value: "valor.sinDps" },
      ],
      searchE: "",
      estudiantes: [],
      searchExD: "",
      infoExD: false,
    };
  },
  async created() {
    this.adminLevel = await this.$store.state.adminLevel;
  },
  mounted() {
    document.title = "Novedades";
    this.buscar();
    this.indicadores();
  },
  methods: {
    hoverE(data, name) {
      for (var i = 0; i < data.length; i++) {
        if (data[i] == name) {
          document.getElementById(name).style.borderTop = "2px solid  #2e7d32";
        } else {
          document.getElementById(data[i]).style.borderTop = "none";
        }
      }
    },
    mostrarE(name, info) {
      this.infoEstudiantes = [];
      // this.lablesE = []
      // this.dataE = []
      this.selected(info, name);
      var infor = this.dataEstudiantes[name];
      // var val = Object.getOwnPropertyNames(infor)
      // console.log(infor)
      // val.forEach((data, i) =>{
      //     console.log(data)
      //     console.log(i)
      //     console.log(Object.getOwnPropertyDescriptor(infor, data).value)
      //     this.infoEstudiantes.push(data)
      //     this.infoEstudiantes[i]['nombre'] = Object.getOwnPropertyDescriptor(infor, data).value
      // })
      var val = Object.values(infor);
      var key = Object.keys(infor);
      for (var i = 0; i < val.length; i++) {
        this.infoEstudiantes.push(val[i]);
        this.infoEstudiantes[i]["nombre"] = key[i];
      }
      this.tableET = true;
    },
    buscar() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          axios.post(host + "/novedades", fd).then((result) => {
            let data = result.data.info;
            this.filtroM = data;
            this.warnigs(this.filtroM);
            // periodos
            var nov = Object.keys(data);
            this.periodosAlertas = Object.values(nov);
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    showCodes(data) {
      this.codesAlertas = Object.keys(this.filtroM[data]);
      this.periodo = data;
      this.showMaterias(data);
      this.selected(this.periodosAlertas, data);
    },
    showMaterias(data) {
      // console.log(data)
      // this.selected(this.codesAlertas, data)
      this.headersAlertas = [];
      this.itemsAlertas = [];
      this.infoTNoved = [];
      var valueP = Object.values(this.filtroM[data]);
      var valueM = Object.values(valueP[0]);
      var valueK = Object.keys(this.filtroM[data]);
      var valueA = Object.keys(valueM[0]);
      // los headers
      this.headersAlertas.push({ text: "CÓDIGO", value: "codigo" });
      this.headersAlertas.push({ text: "NOMBRE", value: "nombre" });

      for (var A = 0, long = valueA.length; A < long; A++) {
        this.headersAlertas.push({ text: valueA[A], value: valueA[A] });
      }
      var extraNombre = [];
      for (var p = 0, longi = valueP.length; p < longi; p++) {
        var nombreM = Object.keys(valueP[p]);
        var dataM = Object.values(valueP[p]);
        for (var m = 0, longit = dataM.length; m < longit; m++) {
          extraNombre.push(dataM[m]);
        }
        extraNombre[p].codigo = valueK[p];
        extraNombre[p].nombre = nombreM;
      }
      console.timeEnd("show1");
      this.itemsAlertas = extraNombre;
      this.tableDT = true;
    },
    warnigs(data) {
      this.mostrarNovedades = true;
      var v = Object.values(data);
      var va = Object.values(v);
      for (var i = 0; i < va.length; i++) {
        var sumar = 0;
        var sum = 0;
        var val = Object.values(va[i]);
        for (var j = 0; j < val.length; j++) {
          var valu = Object.values(val[j]);
          for (var k = 0; k < valu.length; k++) {
            var value = Object.values(valu[k]);
            var key = Object.keys(valu[k]);
            for (var h = 0; h < value.length; h++) {
              if (key[h] == "OK" || key[h] == "NUEVO") {
                sumar = 0;
              } else {
                sum = sumar + sum + value[h];
              }
            }
          }
          // sum = sum + sumar
        }
        this.valores.push(sum);
      }
    },
    indicadores() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "docentes");
          axios.post(host + "/indicadores", fd).then((result) => {
            var k = result.data.distribucion;
            var d = Object.values(k);
            var l = Object.keys(k);
            for (var i = 0, long = d.length; i < long; i++) {
              this.tableDocentes.push({ cantidad: d[i], docentes: l[i] });
            }
            var data = Object.values(result.data.distribucion);
            var labels = Object.keys(result.data.distribucion);
            (this.chartDataDocentes = {
              labels: labels,
              datasets: [
                {
                  label: "Docentes totales: " + result.data.docentes,
                  backgroundColor: "#006e10",
                  data: data,
                },
              ],
            }),
              { responsive: true, maintainAspectRatio: false };
            this.tableCT = false;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        }),
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "estudiantes");
            axios.post(host + "/indicadores", fd).then((result) => {
              this.dataEstudiantes = result.data;
              this.mostrarEstudiantes = true;
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          }),
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "cartera");
            axios.post(host + "/indicadores", fd).then((result) => {
              var cart = result.data.periodos;
              this.deudaT = result.data.deudaMorosa;
              var key = Object.keys(cart);
              var val = Object.values(cart);
              for (let f = 0, long = key.length; f < long; f++) {
                this.cartera.push({ nombre: key[f] });
                this.cartera[f]["valor"] = val[f];
              }
              this.mostrarCartera = true;
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          }),
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "cartera_trabajos");
            axios.post(host + "/indicadores", fd).then((result) => {
              console.log(result);
              var cart = result.data.periodos;
              this.deudaTCT = result.data.deudaMorosa;
              var key = Object.keys(cart);
              var val = Object.values(cart);
              for (let f = 0; f < key.length; f++) {
                this.carteraT.push({ nombre: key[f] });
                this.carteraT[f]["valor"] = val[f];
              }
              this.mostrarCarteraT = true;
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
    },
    up(tipo) {
      if (tipo == "Docente") {
        this.novedades = null;
        this.curso = null;
        this.info = false;
      }
      if (tipo == "Estudiante") {
        this.infoEstudiantes = null;
        this.estudiante = false;
      }
    },
    selected(data, name) {
      var l = data;
      for (var i = 0; i < l.length; i++) {
        if (l[i] == name) {
          document.getElementById(name).style.borderTop = "4px solid #2e7d32";
        } else {
          document.getElementById(l[i]).style.borderTop = "0.5px solid #005005";
        }
      }
      if (this.materia != null && this.periodo != null) {
        this.buscando = true;
        this.buscar();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.titleN {
  padding-bottom: 1%;
  font-size: 16px !important;
  font-weight: 400;
  text-align: center;
}

.notaText {
  padding: 2%;
  font-size: 15px;
  font-weight: 500;
  color: red;
}

.textMenu {
  opacity: 0.6;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(22, 52, 135);
  padding: 0px;
  text-align: center;
}

#v-btn {
  text-transform: lowercase !important;
}

.menuBar {
  width: auto;
  display: inline-block;
  border-radius: 8px 8px 0px 0px;
  border: 0.5px solid rgb(22, 52, 135);

  border-bottom: none;
}
.menuBar:hover {
  color: #e8ffe9;
  opacity: 0.6;
}

.infoData {
  padding: 1%;
  text-align: end;
}
.infoIcon {
  opacity: 0.3 !important;
  color: rgb(22, 52, 135) !important;
}
.top {
  z-index: 999;
}
</style>